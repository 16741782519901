<template>
  <!-- footer -->
  <footer>
      <div class="container-fluid">
        <div class="Footertop">
            <div class="getintouch">
              <h6>Stay in the loop</h6>
              <p>Join our newsletter to receive periodic updates and promotions.</p>
                <form @submit.prevent="submitForm" ref="signUpForm" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                  class="validate site-footer__section__form site-footer__section__form--newsletter"
                  target="_blank"
                  novalidate
                >
                  <div class="inputGrip">
                    <input type="text" name="EMAIL" v-model="signUpForm.email" class="inputstyle required email" id="mce-EMAIL" placeholder="Enter your email address" required>
                    <button type="submit" name="subscribe" id="mc-embedded-subscribe" class="btn-Border buttonSubscribe" form="mc-embedded-subscribe-form">Subscribe</button>
                  </div>
                  <div id="mce-responses" class="form__item clear">
                      <div v-if="signUpError" class="warning" id="mce-error-response">
                        <!-- {{ signUpError }} -->
                        <span class="closeicon"><i class="icon-close"></i></span>
                        <div>
                          <h5>There was a problem with your submission.</h5>
                          <p class="errorsub"><span></span>
                            {{ signUpError }}
                          </p>
                        </div>
                      </div>
                    </div>
                </form>
                <button @click="toTop()" class="btn-Border buttonTop">Back to top <i class="fas fa-arrow-up"></i></button>
              <Loader :visible="loading" fixed blocking />
            </div>
            <div class="footerRight">
              <div class="quicklink">
                  <h6>About us</h6>
                <ul>
                  <li><router-link to="/about">Story</router-link></li>
                  <li><router-link to="/about#team">Team</router-link></li>
                  <li><router-link to="/about#brands">Brands</router-link></li>
                </ul>
              </div>
              <div class="quicklink">
                  <h6>Resources</h6>
                  <ul>
                    <li><router-link to="/sustainability">Sustainability</router-link></li>
                    <li><router-link to="/resources">Donating &amp; recycling</router-link></li>
                    <li><router-link to="/glossary">Glossary</router-link></li>
                  </ul>
              </div>
              <div class="quicklink">
                <h6>Support</h6>
                <ul>
                  <li><router-link to="/contact">Contact</router-link></li>
                  <li><router-link to="/support">FAQ</router-link></li>
                </ul>
              </div>
            </div>
        </div>

        <div class="footerbottom">
            <div class="copyright">
              <ul>
                  <li>@ {{ new Date().getFullYear() }}</li>
                  <li><router-link to="/terms">Terms of service</router-link></li>
                  <li><router-link to="/privacy">Privacy policy</router-link></li>
              </ul>
            </div>
            <div class="social">
              Follow us:
              <a href="https://www.instagram.com/totreeco/"><i class="fa-brands fa-instagram"></i></a>
              <a href="https://www.facebook.com/profile.php?id=100069084462571"><i class="fa-brands fa-facebook"></i></a>
            </div>
        </div>
      </div>
  </footer>
<!--  <div class="arrow-up">-->
<!--    <button v-show="backToTop" class="btn-Border" @click="toTop">Back to top <i class="fas fa-arrow-up"></i></button>-->
<!--  </div>-->
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapMutations } from 'vuex'
import { httpsCallable } from 'firebase/functions'
import Loader from '@/components/Loader'
export default defineComponent({
  name: 'Footer',
  components: {
    Loader
  },
  data () {
    return {
      signUpForm: {},
      signUpError: '',
      loading: false,
      backToTop: false
    }
  },
  computed: {
    ...mapState(['fbFunctions'])
  },
  methods: {
    ...mapMutations(['setGlobalNotification']),
    async submitForm () {
      this.signUpError = ''
      this.loading = true
      const valid = this.$refs.signUpForm.reportValidity()
      if (valid) {
        try {
          await httpsCallable(this.fbFunctions, 'mailChimpSignUp')(this.signUpForm)
          this.setGlobalNotification('Success')
          this.signUpForm = {}
        } catch (err) {
          this.signUpError = err.message
        }
      }
      this.loading = false
    },
    toTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    handleScroll () {
      if (window.scrollY > 300) {
        this.backToTop = true
      } else {
        this.backToTop = false
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount () {
    // Remove the event listener to avoid memory leaks
    window.removeEventListener('scroll', this.handleScroll)
  }
})
</script>
<style scoped>
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
footer h2 {
    font-size: 18px;
    margin-bottom: 10px;
}
h2 {
    margin: 0;
    font-size: 20px;
    color: #000;
    font-family: "test_shnekrftig";
    margin-bottom: 20px;
}
.mb-3 {
    margin-bottom: 1rem!important;
}
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.customCss
{
  margin: unset;
  font-family: inherit;
  padding: 0.375rem 0.75rem;
}
.buttonSubscribe{
  background-color: #F6FF93;
  color: #004021;
}
.buttonTop{
  display: none;
  width: 100%;
  max-width: 438px;
  @media (max-width: 768px) {
    display: flex;
    gap: 8px;
  }
}
</style>
