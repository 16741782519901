import { createRouter, createWebHistory } from 'vue-router'
import HomeNew from '../views/HomeNew.vue'
import store from '@/store/index'

const routes = [
  {
    path: '/',
    name: 'HomeNew',
    component: HomeNew,
    meta: {
      invertNav: true
    }
  },
  {
    path: '/results/:selectionId',
    name: 'Results',
    component: () => import(/* webpackChunkName: "results" */ '../views/Results.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/glossary',
    name: 'Glossary',
    component: () => import(/* webpackChunkName: "glossary" */ '../views/Glossary.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "Error" */ '../views/Error.vue')
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/ResetPassword.vue')
  },
  {
    path: '/checkout/:selectionId',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '../views/Checkout.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import(/* webpackChunkName: "registration" */ '../views/Registration.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: () => import(/* webpackChunkName: "quiz" */ '../views/Quiz.vue'),
    meta: {
      requiresAuth: false // Include "requiresAuth" to only allow logged in users access
    }
  },
  {
    path: '/quiz-complete',
    name: 'Quiz Complete',
    component: () => import(/* webpackChunkName: "quizComplete" */ '../components/quiz/SurveySummary.vue'),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      invertNav: true
    }
  },
  {
    path: '/resources',
    name: 'Resources',
    component: () => import(/* webpackChunkName: "resources" */ '../views/Resources.vue'),
    meta: {
      invertNav: true
    }
  },
  {
    path: '/sustainability',
    name: 'Sustainability',
    component: () => import(/* webpackChunkName: "sustainability" */ '../views/Sustainability.vue'),
    meta: {
      invertNav: true
    }
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import(/* webpackChunkName: "support" */ '../views/Support.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/terms-conditions',
    name: 'TermAndConditions',
    component: () => import(/* webpackChunkName: "contact" */ '../views/TermAndConditions.vue')
  },
  {
    path: '/projects',
    name: 'Boards',
    component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/measurements',
    name: 'Measurements',
    component: () => import(/* webpackChunkName: "measurements" */ '../views/MeasurementsPage.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/Orders.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/terms',
    name: 'Terms of Service',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true // Include "requiresAuth" to only allow logged in users access
    }
  },
  {
    path: '/purchase-success',
    name: 'PurchaseSuccess',
    component: () => import(/* webpackChunkName: "purchase-success" */ '../views/PurchaseSuccess.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/scan-with-rendr',
    name: 'ScanWithRendr',
    component: () => import(/* webpackChunkName: "scan-with-render" */ '../views/ScanWithRendr.vue')
  }, {
    path: '/inspiration-gallery',
    name: 'InspirationGallery',
    component: () => import(/* webpackChunkName: "inspiration-gallery" */ '../views/InspirationGallery.vue')
  },
  {
    path: '/shared-selection/:token',
    name: 'SharedSelection',
    component: () => import('@/views/SharedSelection.vue')
  },
  // Redirect any routes which don't exist back to home
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

// Don't include the utilities view in production
if (process.env.NODE_ENV !== 'production' && process.env.VUE_APP_INCLUDE_UTILITIES === 'true') {
  // View used solely for testing, scratch code, etc...
  routes.push({
    path: '/utilities',
    name: 'Utilities',
    component: () => import(/* webpackChunkName: "utilities" */ '../views/Utilities.vue'),
    meta: {
      requiresAuth: false
    }
  })
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // Check if only query params have changed
    const onlyQueryParamsChanged = to.path === from.path && to.name === from.name

    // If only query params changed, don't scroll
    if (onlyQueryParamsChanged) {
      return false
    }
    // When routing to a new page with a hash, we have to wait for the page to load,
    // so that the element with the target ID exists in the DOM
    if (to.hash && to.path !== from.path) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ el: to.hash, behavior: 'smooth' })
        }, 400)
      })
      // Scroll to hash on the page if it's in the URL
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
      // We don't want to scroll up to Results, as it can cause issues with furniture placement
    } else if (to.name === 'Results') {
      return {
        top: 0,
        behavior: 'instant'
      }
      // For all other cases, including the InspirationGallery
    } else {
      // If there's a saved position (e.g., when using browser back/forward), use that
      if (savedPosition) {
        return savedPosition
      }
      // Otherwise, scroll to the top of the page
      return { top: 0, behavior: 'smooth' }
    }
  }
})

router.beforeResolve((to, from, next) => {
  next()
})

router.afterEach((to, from) => {
})

router.beforeEach(async (to, from, next) => {
  // Before allowing the user to access the route, check for authentication requirements.
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const invertNav = to.matched.some(record => record.meta.invertNav)

  if (invertNav) {
    store.commit('setInvertNav', true)
  } else {
    store.commit('setInvertNav', false)
  }

  store.commit('setModalSignup', false)
  store.commit('setModalAdditionalInfo', false)
  store.commit('setModalOffer', false)

  if (requiresAuth) {
    if (to.path === '/projects' || to.path === '/orders') {
      store.commit('setPageLoading', true)
    }
    // if authenticated and router go to registration then go to a different page to.pathName is authenciated or not, redirect to quiz

    const authenticated = await store.dispatch('userAuthCheck')

    if (authenticated && to.path === '/registration') {
      // Web User who is already registered tried to go to the registration page without logging out.
      next('/quiz')
    } else if (!authenticated && to.path === '/registration') {
      // Web User who is NOT registered needs to be able to register.
      next()
    } else if (!authenticated) {
      store.commit('setPageLoading', false)
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
