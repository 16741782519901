<template>
  <fieldset class="form__question question-div">
    <div :class="{ 'form__content': true, 'softStyle': shouldShowImageDiv }">
      <legend class="quiz__subheading" :class="{ 'meta': metaTitle }" v-html="question.question" />
      <div :class="{'radiosWrapper': true, 'customLayout': shouldShowImageDiv  }">
      <div v-for="(inputFields, index) in fields" :key="index" :class="{'form__item form__item--radio': true, 'customLayout--child': shouldShowImageDiv, 'customLayout--child-1': shouldShowImageDiv && index === 0}">
        <input
          :value="inputFields.value !== undefined ? inputFields.value : inputFields.text"
          type="radio"
          :name="(questionsRefId || question?.questionsRefId) + '--' + index"
          :id="(questionsRefId || question?.questionsRefId) + '--' + index"
          v-model="radioValue"
          @input="updateValue"
          @focusout="handleFocusout"
          class="customRadio"
          @change="handleRadioChange"
        >
        <label :for="(questionsRefId || question?.questionsRefId) + '--' + index">{{ inputFields.text }}</label>
        <label class="sofaimage" :for="(questionsRefId || question?.questionsRefId) + '--' + index" v-if="shouldShowImageDiv">
          <img :src="require(`@/assets/images/${inputFields.image}`)" class="image" alt="Image of a furniture">
        </label>
        <div class="instaorder"  v-if="index === 0 && menuType === 'timeline'">Insta-order <i class="icon-circle-info"></i> <span>Don't want to sit on milk crates for a month? With <strong>Insta-Order</strong>, all of your furniture options will be in-stock and ready to ship.</span></div>
      </div>
      </div>
      <ValidationWarning :valid="valid || !shouldValidate" />
    </div>
  </fieldset>
</template>

<script>
import ValidationWarning from '@/components/quiz/questions/ValidationWarning.vue'

export default {
  components: { ValidationWarning },
  props: {
    menuType: String,
    typeName: String,
    modelValue: {
      type: [String, Number],
      default: undefined
    },
    question: {
      type: Object,
      default: () => {}
    },
    questionsRefId: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    metaTitle: {
      type: Boolean,
      default: false
    },
    autoProceed: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    shouldShowImageDiv () {
      // Check if the typeName is 'type_of_sofa'
      return this.typeName === 'type_of_sofa'
    },
    fields () {
      return this.shouldShowImageDiv ? [...this.question.inputFields].reverse() : this.question.inputFields
    }
  },
  data () {
    return {
      radioValue: undefined,
      valid: true,
      shouldValidate: false,
      isInitialLoad: true
    }
  },
  created () {
    if (this.modelValue !== undefined) {
      const hasNumericValues = this.question?.inputFields?.some(field =>
        typeof field.value === 'number' ||
        (typeof field.value === 'string' && !isNaN(Number(field.value)) && field.value !== '')
      )

      if (hasNumericValues && typeof this.modelValue !== 'number' && this.modelValue !== null && this.modelValue !== '') {
        this.radioValue = Number(this.modelValue)
      } else {
        this.radioValue = this.modelValue
      }

      this.shouldValidate = this.modelValue !== null && this.modelValue !== ''
    }
  },
  mounted () {
    this.validate()

    setTimeout(() => {
      this.isInitialLoad = false
    }, 100)
  },
  methods: {
    validate () {
      const isValueEmpty = this.radioValue === null || this.radioValue === undefined || this.radioValue === ''
      this.valid = !(this.required && isValueEmpty)

      if (!this.isInitialLoad || !isValueEmpty) {
        this.shouldValidate = true
      }

      return this.valid
    },
    handleFocusout () {
      this.shouldValidate = true
      this.validate()
    },
    handleRadioChange (event) {
      this.shouldValidate = true

      const selectedField = this.fields.find(field =>
        String(field.value) === event.target.value || field.text === event.target.value
      )

      if (selectedField && typeof selectedField.value === 'number') {
        this.radioValue = Number(event.target.value)
      } else {
        this.radioValue = event.target.value
      }

      this.$emit('update:modelValue', this.radioValue)

      if (this.autoProceed) {
        this.$emit('radioClicked', this.radioValue)
      }

      this.validate()
    },
    updateValue (e) {
      this.shouldValidate = true

      const selectedField = this.fields.find(field =>
        String(field.value) === e.target.value || field.text === e.target.value
      )

      let value = e.target.value
      if (selectedField && typeof selectedField.value === 'number') {
        value = Number(value)
      }

      this.$emit('update:modelValue', value)
      this.validate()
    }
  },
  watch: {
    modelValue: {
      handler (newValue) {
        if (newValue === undefined) {
          this.radioValue = undefined
          return
        }

        const hasNumericValues = this.question?.inputFields?.some(field => typeof field.value === 'number')

        if (hasNumericValues && newValue !== null && newValue !== undefined && newValue !== '' && typeof newValue !== 'number') {
          this.radioValue = Number(newValue)
        } else if (newValue !== this.radioValue) {
          this.radioValue = newValue
        }

        if (newValue !== null && newValue !== undefined && newValue !== '') {
          this.shouldValidate = true
        }

        this.validate()
      },
      immediate: true
    },
    radioValue: {
      handler (newValue) {
        this.$emit('update:modelValue', newValue)
        this.validate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.meta {
  @include meta-text;
  margin-bottom: var(--gutter) !important;
  font-size: 0.75rem !important;
}
.radiosWrapper {
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 var(--gutter);
  text-transform: capitalize;
}
.customLayout {
  display: flex;
  align-items: center;
  flex-flow: column-reverse;
  @media (min-width: 768px) {
    flex-flow: row-reverse wrap-reverse;
    justify-content: center;
  }
  @media (min-width: 1024px) {
    grid-column-start: 3;
    grid-column-end: 11;
  }

  &--child {
    flex: 0 0 30%;
    @media (max-width: 768px) {
      flex: 1;
      width: 80%;
    }
  }
}

.softStyle {
  grid-column: span 12;
}

.image {
  max-height: 128px !important;
  width: auto !important;
}
</style>
