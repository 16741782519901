<template>
  <fieldset class="textInput">
    <div class="form__content">
      <legend class="quiz__subheading">{{ question?.question || question }}</legend>
      <div :class="`form__item form__item--text ${variant==='outlined' ? 'form-text--outlined': ''} ${isProjectName? 'projectName':''}`">
        <input
          :class="`${variant==='outlined' ? 'outlined' : ''}`"
          :type="fieldType"
          :name="question?.answerRefId"
          :placeholder="question?.placeholder || ''"
          v-model="inputValue"
          min="0"
          @input="updateValue"
          @blur="handleBlur"
          @keyup.enter="()=>{
            if (this.$refs.inputField) {
                this.$refs.inputField.blur();
              }
              handleBlur()
          }"
          ref="inputField"
        >
      </div>
      <ValidationWarning :valid="valid" />
    </div>
  </fieldset>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    initialValue: {
      type: String,
      default: null
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    question: {
      type: Object,
      default: () => {}
    },
    fieldType: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    onBlurCallback: {
      type: Function,
      default: null
    },
    isProjectName: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: true,
      inputValue: '',
      initialInputValue: '',
      valueChanged: false
    }
  },
  mounted () {
    this.inputValue = this.modelValue || ''
    if (!this.inputValue && this.initialValue) {
      this.inputValue = this.initialValue
      this.$emit('update:modelValue', this.initialValue)
    }

    this.initialInputValue = this.inputValue
  },
  methods: {
    updateValue () {
      this.$emit('update:modelValue', this.inputValue)

      this.valueChanged = this.inputValue !== this.initialInputValue
    },
    validate () {
      this.valid = !(this.required && !this.modelValue)
      // 0 should pass validation
      if (this.modelValue === 0) this.valid = true
      return this.valid
    },
    handleBlur () {
      if (this.valueChanged && this.onBlurCallback) {
        this.onBlurCallback(this.inputValue)

        this.valueChanged = false

        this.initialInputValue = this.inputValue
      }
    }
  },
  watch: {
    modelValue (value) {
      this.validate()
    }
  }
}
</script>

<style lang="scss" scoped>

.form_question--outlined {
  position: initial !important;
  display: initial !important;
  padding: 0 !important;
}

.form-text--outlined {
  margin-bottom: 0 !important;
  &::before {
    display: none;
  }
}

.outlined {
  border: 1px solid #0C372033;
  padding: 5px 10px !important;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  color: #000;
  margin-top: 10px;
  background-color: #FBFCFD;
  margin-bottom: 0;
  &:focus {
    background-color: #FBFCFD !important;
  }
}

  .projectName input{
    font-weight: 500 !important;
    font-size: 24px;
    letter-spacing: -0.96px;
    text-align: center;
  }

  .projectName {
    width: fit-content;
    &::before{
      display: none !important;
    }
    &:has(input:focus)::before {
      display: block !important;
    }
  }
</style>
