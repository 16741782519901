<template>
  <div class="styleSection">
    <h2>{{ title }}</h2>
    <p class="styleSubtitle">{{ subtitle }}</p>
    <p class="styleDescription">{{ description }}</p>

    <div class="imageGrid" v-if="variant==='accordion'">
      <CloudinaryImage   :public-id="collagePublicId" :alt="`${room} inspiration`" />
    </div>

    <div class="imageCollage" v-if="variant==='carousel'">
      <CloudinaryImage :public-id="collagePublicId" :alt="`${room} inspiration`" />
    </div>

    <div class="accordionSection" v-if="variant==='accordion'">
      <AccordionItem
        v-for="item in accordionItems"
        :key="item.title"
        :title="item.title"
        :expanded="item.expanded"
        :base-url="item.baseUrl"
      />
    </div>
    <div v-else-if="variant==='carousel'" class="carouselsSection">
      <CarouselItem
        v-for="item in accordionItems"
        :key="item.title"
        :title="item.title"
        :expanded="item.expanded"
        :base-url="item.baseUrl"
      />
    </div>
  </div>
</template>

<script>
import AccordionItem from './AccordionItem.vue'
import { mapState } from 'vuex'
import CloudinaryImage from '@/components/CloudinaryImage.vue'
import CarouselItem from '@/components/InspirationGallery/CarouselItem.vue'

export default {
  name: 'StyleSection',
  components: {
    CarouselItem,
    CloudinaryImage,
    AccordionItem
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    room: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      default: 'accordion'
    },
    baseDirectory: {
      type: String,
      default: 'exploration'
    }
  },
  data () {
    return {
      accordionItems: this.getAccordionItems(),
      collagePublicId: `ToTree/webapp/${this.baseDirectory}/${this.room.trim().toLowerCase().replace(' ', '_')}/${this.title.trim().toLowerCase().replace(' ', '_')}/collage`
    }
  },
  computed: {
    ...mapState(['fbFunctions'])
  },
  methods: {
    getAccordionItems () {
      switch (this.room?.toLowerCase()) {
        case 'bedroom':
          return [
            { title: 'Beds', expanded: false, baseUrl: `ToTree/webapp/${this.baseDirectory}/${this.room.trim().toLowerCase().replace(' ', '_')}/${this.title.trim().toLowerCase().replace(' ', '_')}/Beds` },
            { title: 'Storage', expanded: false, baseUrl: `ToTree/webapp/${this.baseDirectory}/${this.room.trim().toLowerCase().replace(' ', '_')}/${this.title.trim().toLowerCase().replace(' ', '_')}/storage` },
            { title: 'Additional', expanded: false, baseUrl: `ToTree/webapp/${this.baseDirectory}/${this.room.trim().toLowerCase().replace(' ', '_')}/${this.title.trim().toLowerCase().replace(' ', '_')}/Additional` }
          ]
        case 'living room':
          return [
            { title: 'Seating', expanded: false, baseUrl: `ToTree/webapp/${this.baseDirectory}/${this.room.trim().toLowerCase().replace(' ', '_')}/${this.title.trim().toLowerCase().replace(' ', '_')}/seating` },
            { title: 'Storage', expanded: false, baseUrl: `ToTree/webapp/${this.baseDirectory}/${this.room.trim().toLowerCase().replace(' ', '_')}/${this.title.trim().toLowerCase().replace(' ', '_')}/storage` },
            { title: 'Tables', expanded: false, baseUrl: `ToTree/webapp/${this.baseDirectory}/${this.room.trim().toLowerCase().replace(' ', '_')}/${this.title.trim().toLowerCase().replace(' ', '_')}/tables` }
          ]
        case 'dining room':
          return [
            { title: 'Seating', expanded: false, baseUrl: `ToTree/webapp/${this.baseDirectory}/${this.room.trim().toLowerCase().replace(' ', '_')}/${this.title.trim().toLowerCase().replace(' ', '_')}/seating` },
            { title: 'Tables', expanded: false, baseUrl: `ToTree/webapp/${this.baseDirectory}/${this.room.trim().toLowerCase().replace(' ', '_')}/${this.title.trim().toLowerCase().replace(' ', '_')}/tables` }
          ]
        default:
          return []
      }
    }
  }
}
</script>

<style scoped>
.styleSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1024px) {
    padding: 0 80px;
  }
}

.styleSection h2 {
  font-family: League Spartan, serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 36.8px;
  text-align: center;
  color: #0B1326;
}

.styleSubtitle {
  font-family: Sohne, serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.48px;
  text-align: center;
  color: #0B1326;
  margin-bottom: 16px;
  margin-top: 4px;

}

.styleDescription {
  margin-bottom: 20px;
  font-family: Sohne, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  max-width: 800px;
}

.imageGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
}

.imageGrid > * {
  width: calc(33.33% - 5.33px); /* (8px * 2) / 3 = 5.33px */
  height: auto !important;
}

@media (max-width: 767px) {
  .imageGrid > * {
    width: 100%;
  }
}

.accordionSection {
  margin-top: 40px;
  width: 100%;
}

.imageCollage{
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselsSection {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
}
</style>
